var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var pristine = _ref.pristine;
        return [_c('b-modal', {
          attrs: {
            "id": "modal-reservation-modify-add-other-special-services",
            "title": 'Thêm dịch vụ phòng chờ thương gia',
            "title-class": "text-airline font-medium-4 font-weight-bolder mb-0",
            "body-class": "px-75",
            "footer-class": "px-50",
            "centered": "",
            "no-close-on-backdrop": "",
            "size": "xl"
          },
          on: {
            "show": _vm.handleShowModal,
            "hide": _vm.handleHideModal
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var close = _ref2.close;
              return [_c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(186, 191, 199, 0.15)',
                  expression: "'rgba(186, 191, 199, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "center rounded-pill",
                attrs: {
                  "size": "md",
                  "variant": "outline-secondary px-1 px-sm-2"
                },
                on: {
                  "click": function click($event) {
                    return close();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "px-1 px-sm-2",
                attrs: {
                  "variant": "success",
                  "disabled": !_vm.isConfirm || pristine,
                  "pill": ""
                },
                on: {
                  "click": _vm.handleAddOtherSpecialServices
                }
              }, [_c('span', {
                staticClass: "align-middle"
              }, [_vm._v(" Thanh toán ngay ")])])];
            }
          }], null, true)
        }, [_c('IAmOverlay', {
          attrs: {
            "loading": _vm.isLoading,
            "spinner-variant": 'info'
          }
        }, [_c('b-card', {
          staticClass: "border",
          attrs: {
            "body-class": "p-75"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Phone",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "phone"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" Số điện thoại liên hệ "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "phone",
                  "placeholder": "Vui lòng nhập",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.phoneNumber,
                  callback: function callback($$v) {
                    _vm.phoneNumber = $$v;
                  },
                  expression: "phoneNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "lg": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email|max:150"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "email"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" Email liên hệ "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "placeholder": "Vui lòng nhập",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.email,
                  callback: function callback($$v) {
                    _vm.email = $$v;
                  },
                  expression: "email"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1), _vm._l(_vm.dataForm, function (paxItem, indexPaxItem) {
          return _c('b-card', {
            key: indexPaxItem,
            staticClass: "border-warning",
            attrs: {
              "header-bg-variant": "light-warning",
              "header-class": "py-25 px-25 px-md-1",
              "body-class": "p-75"
            },
            scopedSlots: _vm._u([{
              key: "header",
              fn: function fn() {
                return [_c('div', {
                  staticClass: "text-body fw-700"
                }, [_vm._v(" Hành khách #" + _vm._s(indexPaxItem + 1) + " ")]), _c('div', [_c('b-button', {
                  directives: [{
                    name: "ripple",
                    rawName: "v-ripple.400",
                    value: 'rgba(113, 102, 240, 0.15)',
                    expression: "'rgba(113, 102, 240, 0.15)'",
                    modifiers: {
                      "400": true
                    }
                  }],
                  staticClass: "fw-700 mr-1 px-75 py-50 py-md-75 px-md-1",
                  attrs: {
                    "variant": "outline-info rounded-lg",
                    "disabled": _vm.hasAllData(paxItem)
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.handleOneForAll(paxItem);
                    }
                  }
                }, [_vm._v(" Áp dụng tất cả ")]), _c('b-button', {
                  directives: [{
                    name: "ripple",
                    rawName: "v-ripple.400",
                    value: 'rgba(113, 102, 240, 0.15)',
                    expression: "'rgba(113, 102, 240, 0.15)'",
                    modifiers: {
                      "400": true
                    }
                  }],
                  staticClass: "fw-700",
                  attrs: {
                    "variant": "outline-danger rounded-lg px-1 py-50 py-md-75 px-md-2"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.handleRemovePaxItem(indexPaxItem);
                    }
                  }
                }, [_vm._v(" Xoá ")])], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-row', [_c('b-col', {
            attrs: {
              "cols": "12",
              "lg": "6",
              "xl": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Tên khách hàng sử dụng vé",
              "rules": "required",
              "vid": "passenger-name-".concat(indexPaxItem)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_vm._v(" Tên khách hàng sử dụng "), _c('span', {
                        staticClass: "text-danger"
                      }, [_vm._v("(*)")])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('v-select', {
                  staticClass: "select_custom",
                  attrs: {
                    "id": "passenger-name-".concat(indexPaxItem),
                    "clearable": false,
                    "searchable": true,
                    "label": "firstName",
                    "placeholder": " Vui lòng chọn hành khách",
                    "options": _vm.bookingData.paxLists
                  },
                  scopedSlots: _vm._u([{
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "fw-700"
                      }, [_c('small', {
                        staticClass: "font-weight-bold text-body font-medium-1  mr-1"
                      }, [_vm._v("(" + _vm._s(_vm.$t("reservation.".concat(data.paxType))) + ")")]), _c('span', {
                        staticClass: "font-weight-bolder text-info"
                      }, [_vm._v(_vm._s(data.lastName) + " " + _vm._s(data.firstName))])])];
                    }
                  }, {
                    key: "option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "fw-700"
                      }, [_c('span', {
                        staticClass: "font-weight-bold text-body font-medium-1 mr-1"
                      }, [_vm._v("(" + _vm._s(_vm.$t("reservation.".concat(data.paxType))) + ")")]), _c('span', {
                        staticClass: "font-weight-bolder"
                      }, [_vm._v(_vm._s(data.lastName) + " " + _vm._s(data.firstName))])])];
                    }
                  }, {
                    key: "no-options",
                    fn: function fn() {
                      return [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: paxItem.pax,
                    callback: function callback($$v) {
                      _vm.$set(paxItem, "pax", $$v);
                    },
                    expression: "paxItem.pax"
                  }
                }), validationContext.errors.length > 0 ? _c('span', {
                  staticClass: "text-danger",
                  staticStyle: {
                    "font-size": "12px"
                  }
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            staticClass: "px-xl-0",
            attrs: {
              "cols": "12",
              "lg": "6",
              "xl": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Hành trình",
              "rules": "required",
              "vid": "flight-select-".concat(indexPaxItem)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_vm._v(" Chọn hành trình "), _c('span', {
                        staticClass: "text-danger"
                      }, [_vm._v("(*)")])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('v-select', {
                  staticClass: "select_custom",
                  attrs: {
                    "id": "flight-select-".concat(indexPaxItem),
                    "clearable": false,
                    "searchable": false,
                    "label": "devAddonsSegmentIndex",
                    "placeholder": " Chọn hành trình",
                    "options": _vm.getSegmentOptions(_vm.bookingData)
                  },
                  scopedSlots: _vm._u([{
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "d-flex flex-wrap text-info font-weight-bolder"
                      }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(data, false)) + " ")])];
                    }
                  }, {
                    key: "option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "d-flex flex-wrap fw-700"
                      }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(data, false)) + " ")])];
                    }
                  }, {
                    key: "no-options",
                    fn: function fn() {
                      return [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: paxItem.tripSelected,
                    callback: function callback($$v) {
                      _vm.$set(paxItem, "tripSelected", $$v);
                    },
                    expression: "paxItem.tripSelected"
                  }
                }), validationContext.errors.length > 0 ? _c('span', {
                  staticClass: "text-danger",
                  staticStyle: {
                    "font-size": "12px"
                  }
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "12",
              "lg": "6",
              "xl": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Sân bay",
              "rules": "required",
              "vid": "airport-select-".concat(indexPaxItem)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_vm._v(" Chọn sân bay "), _c('span', {
                        staticClass: "text-danger"
                      }, [_vm._v("(*)")])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('v-select', {
                  staticClass: "select_custom",
                  attrs: {
                    "id": "airport-select-".concat(indexPaxItem),
                    "clearable": false,
                    "searchable": true,
                    "label": "name",
                    "placeholder": " Chọn sân bay",
                    "options": _vm.getSpecialServicesList(_vm.specialServicesList)
                  },
                  on: {
                    "option:selected": function optionSelected() {
                      paxItem.serviceSelected = null;
                      paxItem.loungeFare = null;
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "d-flex flex-wrap text-info font-weight-bolder"
                      }, [_vm._v(" " + _vm._s(data.name) + " ")])];
                    }
                  }, {
                    key: "option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "d-flex flex-wrap fw-700"
                      }, [_vm._v(" " + _vm._s(data.name) + " ")])];
                    }
                  }, {
                    key: "no-options",
                    fn: function fn() {
                      return [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: paxItem.airportSelected,
                    callback: function callback($$v) {
                      _vm.$set(paxItem, "airportSelected", $$v);
                    },
                    expression: "paxItem.airportSelected"
                  }
                }), validationContext.errors.length > 0 ? _c('span', {
                  staticClass: "text-danger",
                  staticStyle: {
                    "font-size": "12px"
                  }
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "7",
              "lg": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Phòng chờ",
              "rules": "required",
              "vid": "lounge-select-".concat(indexPaxItem)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_vm._v(" Chọn phòng chờ "), _c('span', {
                        staticClass: "text-danger"
                      }, [_vm._v("(*)")])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('v-select', {
                  staticClass: "select_custom",
                  attrs: {
                    "id": "lounge-select-".concat(indexPaxItem),
                    "clearable": false,
                    "searchable": true,
                    "label": "name",
                    "placeholder": " Chọn phòng chờ",
                    "options": _vm.getLoungeServicesList(paxItem.airportSelected)
                  },
                  on: {
                    "option:selected": function optionSelected() {
                      paxItem.loungeFare = null;
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "d-flex flex-wrap text-info font-weight-bolder"
                      }, [_vm._v(" " + _vm._s(data.name) + " (" + _vm._s(data.isDomestic ? 'Nội địa' : 'Quốc tế') + ") ")])];
                    }
                  }, {
                    key: "option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "d-flex flex-wrap fw-700"
                      }, [_vm._v(" " + _vm._s(data.name) + " (" + _vm._s(data.isDomestic ? 'Nội địa' : 'Quốc tế') + ") ")])];
                    }
                  }, {
                    key: "no-options",
                    fn: function fn() {
                      return [_vm._v(" " + _vm._s(paxItem.airportSelected ? _vm.$t('flight.noOptions') : 'Vui lòng chọn sân bay trước.') + " ")];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: paxItem.serviceSelected,
                    callback: function callback($$v) {
                      _vm.$set(paxItem, "serviceSelected", $$v);
                    },
                    expression: "paxItem.serviceSelected"
                  }
                }), validationContext.errors.length > 0 ? _c('span', {
                  staticClass: "text-danger",
                  staticStyle: {
                    "font-size": "12px"
                  }
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            staticClass: "pl-0 pl-md-1",
            attrs: {
              "cols": "5",
              "lg": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Loại vé",
              "rules": "required",
              "vid": "fare-select-".concat(indexPaxItem)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_vm._v(" Chọn loại vé "), _c('span', {
                        staticClass: "text-danger"
                      }, [_vm._v("(*)")])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('v-select', {
                  staticClass: "select_custom",
                  attrs: {
                    "id": "fare-select-".concat(indexPaxItem),
                    "clearable": false,
                    "searchable": true,
                    "label": "name",
                    "placeholder": " Chọn loại vé",
                    "options": _vm.getLoungeServiceFareList(paxItem.serviceSelected)
                  },
                  scopedSlots: _vm._u([{
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "d-flex flex-nowrap text-info font-weight-bolder"
                      }, [_vm._v(" " + _vm._s(data.name) + " "), _c('span', {
                        staticClass: "text-warning ml-50"
                      }, [_vm._v("(" + _vm._s(_vm.formatCurrency(data.price)) + ")")])])];
                    }
                  }, {
                    key: "option",
                    fn: function fn(data) {
                      return [_c('div', {
                        staticClass: "d-flex flex-nowrap fw-700"
                      }, [_vm._v(" " + _vm._s(data.name) + " "), _c('span', {
                        staticClass: "text-warning ml-50"
                      }, [_vm._v("(" + _vm._s(_vm.formatCurrency(data.price)) + ")")])])];
                    }
                  }, {
                    key: "no-options",
                    fn: function fn() {
                      return [_vm._v(" " + _vm._s(paxItem.serviceSelected ? _vm.$t('flight.noOptions') : 'Vui lòng chọn phòng chờ trước!') + " ")];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: paxItem.loungeFare,
                    callback: function callback($$v) {
                      _vm.$set(paxItem, "loungeFare", $$v);
                    },
                    expression: "paxItem.loungeFare"
                  }
                }), validationContext.errors.length > 0 ? _c('span', {
                  staticClass: "text-danger",
                  staticStyle: {
                    "font-size": "12px"
                  }
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1)], 1)], 1);
        }), _c('div', [_c('b-button', {
          staticClass: "rounded-lg",
          attrs: {
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.addPassengerHandle();
            }
          }
        }, [_vm._v(" Thêm hành khách ")])], 1), _c('b-row', {
          staticClass: "justify-content-center align-items-center mt-2 fw-700"
        }, [_vm._v(" Tổng tiền cần thanh toán: "), _c('span', {
          staticClass: "text-airline fw-800 ml-50 font-medium-2"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPriceServices)))])]), _c('IAmConfirm', {
          attrs: {
            "is-confirm": _vm.isConfirm
          },
          on: {
            "update:isConfirm": function updateIsConfirm($event) {
              _vm.isConfirm = $event;
            },
            "update:is-confirm": function updateIsConfirm($event) {
              _vm.isConfirm = $event;
            }
          }
        })], 2)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }