var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', _vm._g(_vm._b({
    attrs: {
      "no-body": ""
    }
  }, 'b-card', _vm.cardAttrs, false), _vm.$listeners), [_c('div', {
    staticClass: "card-header"
  }, [_c('div', [_c('b-card-title', [_vm._v(_vm._s(_vm.$attrs.title))]), _vm.$attrs['sub-title'] ? _c('b-card-sub-title', [_vm._v(" " + _vm._s(_vm.$attrs['sub-title']) + " ")]) : _vm._e()], 1), _c('i', {
    staticClass: "code-toggler feather icon-code cursor-pointer",
    attrs: {
      "aria-expanded": !_vm.code_visible ? 'true' : 'false',
      "aria-controls": _vm.parentID
    },
    on: {
      "click": function click($event) {
        _vm.code_visible = !_vm.code_visible;
      }
    }
  })]), _vm.$attrs['no-body'] !== undefined ? [_vm._t("default"), _c('b-collapse', {
    staticClass: "card-code",
    attrs: {
      "id": _vm.parentID,
      "visible": _vm.code_visible
    },
    model: {
      value: _vm.code_visible,
      callback: function callback($$v) {
        _vm.code_visible = $$v;
      },
      expression: "code_visible"
    }
  }, [_c('b-card-body', [_c('prism', {
    attrs: {
      "language": _vm.codeLanguage
    }
  }, [_vm._t("code")], 2)], 1)], 1)] : _c('b-card-body', [_vm._t("default"), _c('b-collapse', {
    staticClass: "card-code",
    attrs: {
      "id": _vm.parentID,
      "visible": _vm.code_visible
    },
    model: {
      value: _vm.code_visible,
      callback: function callback($$v) {
        _vm.code_visible = $$v;
      },
      expression: "code_visible"
    }
  }, [_c('div', {
    staticClass: "p-1"
  }), _c('prism', {
    attrs: {
      "language": _vm.codeLanguage
    }
  }, [_vm._t("code")], 2)], 1)], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }